import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

/**
 * Screens
 */

export const historyScreen = new analytics.Event({
  displayName: 'History screen',
  name: 'history_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
