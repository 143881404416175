import { Action, ActionDiagramContentContextComment, ActionDiagramContentContextConnection, ActionDiagramContentContextObject, ModelObjectType } from '@icepanel/platform-api-client'

const createIcon = {
  icon: '$fas-plus',
  iconColor: 'green'
} as const

const updateIcon = {
  icon: '$fas-sync',
  iconColor: 'grey lighten-4'
} as const

const deleteIcon = {
  icon: '$fas-trash-alt',
  iconColor: 'error'
} as const

const versionIcon = {
  icon: '$fas-snowflake',
  iconColor: 'accent'
} as const

const viewedIcon = {
  icon: '$fas-eye',
  iconColor: 'accent'
} as const

const diagramIcon = '$fas-project-diagram'
const diagramGroupIcon = '$fas-folder-open'
const modelObjectIcons: Record<ModelObjectType, string | undefined> = {
  actor: undefined,
  app: undefined,
  component: undefined,
  group: undefined,
  root: undefined,
  store: undefined,
  system: undefined
}
const commentIcon = undefined
const modelConnectionIcon = '$fas-arrows-alt-h'
const flowIcon = '$custom-solid-repeat'
const tagIcon = '$fas-tag'
const tagGroupIcon = '$fas-tags'

const wordify = (str: string) => str?.replaceAll('-', ' ').replaceAll('_', '').trim()
const capitalize = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1)

export interface IActionLabelContentLink {
  commentHandleId?: string
  diagramGroupHandleId?: string
  diagramHandleId?: string
  domainHandleId?: string
  flowHandleId?: string
  modelConnectionHandleId?: string
  modelObjectHandleId?: string
}

export interface IActionLabelContent {
  icon?: string
  link?: IActionLabelContentLink
  text: string
  tooltip?: string
}

export interface IActionLabel {
  content: IActionLabelContent[]
  icon?: string
  iconColor?: string
}

export default (action: Action): IActionLabel => {
  switch (action.type) {
    case 'version-create': {
      const model = action.context.modelFamily?.[0]
      if (model) {
        return {
          ...versionIcon,
          content: [{
            text: `Version ${action.props.name}`
          }, {
            text: 'for'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.type === 'root' ? action.context.domainName || '' : model.name || wordify(model.type),
            tooltip: model.type === 'root' ? 'Domain' : model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else {
        return {
          ...versionIcon,
          content: [{
            text: `Version ${action.props.name}`
          }]
        }
      }
    }
    case 'version-revert-create': return {
      ...versionIcon,
      content: [{
        text: `Reverted to version ${action.context.name}`
      }]
    }

    case 'comment-create': {
      return {
        ...createIcon,
        content: [{
          text: 'Created comment'
        }]
      }
    }

    case 'diagram-create': {
      const model = action.context.modelFamily?.[0]
      if (model && model.type !== 'root') {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.props.name || wordify(action.props.type),
            tooltip: action.props.name ? capitalize(wordify(action.props.type)) : undefined
          }, {
            text: 'inside'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.name || wordify(model.type),
            tooltip: model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.props.name || wordify(action.props.type),
            tooltip: action.props.name ? capitalize(wordify(action.props.type)) : undefined
          }]
        }
      }
    }
    case 'diagram-update': {
      const model = action.context.modelFamily?.[0]
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'to'
          }, {
            link: {
              diagramHandleId: action.id
            },
            text: action.props.name || 'empty'
          }]
        }
      } else if (action.props.description !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'description'
          }]
        }
      } else if (action.props.modelId !== undefined && model && model.type === 'root' && action.context.domainHandleId && action.context.domainName) {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'to'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              domainHandleId: action.context.domainHandleId
            },
            text: action.context.domainName || 'Domain',
            tooltip: 'Domain'
          }]
        }
      } else if (action.props.modelId !== undefined && model && model.type !== 'root') {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'to'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.name || wordify(model.type),
            tooltip: model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else if (action.props.groupId !== undefined && action.context.groupHandleId && action.context.groupName) {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'into'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.context.groupHandleId
            },
            text: action.context.groupName || 'Diagram group',
            tooltip: action.context.groupName ? 'Diagram group' : undefined
          }]
        }
      } else if (action.props.modelId !== undefined || action.props.groupId !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: `Updated ${wordify(action.context.type)} ${action.context.name}`
          }]
        }
      }
    }
    case 'diagram-delete': {
      const model = action.context.modelFamily?.[0]
      if (model && model.type !== 'root') {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'from'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.name || wordify(model.type),
            tooltip: model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      }
    }

    case 'diagram-content-view': {
      const model = action.context.modelFamily?.[0]
      if (model && model.type !== 'root') {
        const content: IActionLabelContent[] = [{
          text: 'Viewed'
        }, {
          icon: diagramIcon,
          link: {
            diagramHandleId: action.id
          },
          text: action.context.name || wordify(action.context.type),
          tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
        }]

        return {
          ...viewedIcon,
          content
        }
      } else {
        return {
          ...viewedIcon,
          content: [{
            text: 'Viewed'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      }
    }
    case 'diagram-content-create': {
      const model = action.context.modelFamily?.[0]
      if (model && model.type !== 'root') {
        const content: IActionLabelContent[] = [{
          text: 'Created'
        }, {
          icon: diagramIcon,
          link: {
            diagramHandleId: action.id
          },
          text: action.context.name || wordify(action.context.type),
          tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
        }, {
          text: 'inside'
        }, {
          icon: modelObjectIcons[model.type],
          link: {
            modelObjectHandleId: model.handleId
          },
          text: model.name || wordify(model.type),
          tooltip: model.name ? capitalize(wordify(model.type)) : undefined
        }]

        if (action.context.objects?.length) {
          content.push({
            text: 'with'
          })
          Object.values(action.context.objects).forEach(o => {
            content.push({
              icon: modelObjectIcons[o.modelType],
              link: {
                diagramHandleId: action.id,
                modelObjectHandleId: o.modelHandleId
              },
              text: o.modelName || o.modelType,
              tooltip: o.modelName ? capitalize(wordify(o.modelType)) : undefined
            })
          })
        }

        if (action.context.connections?.length) {
          content.push({
            text: 'and'
          })
          Object.values(action.context.connections).forEach(o => {
            content.push({
              icon: modelConnectionIcon,
              link: {
                diagramHandleId: action.id,
                modelConnectionHandleId: o.modelHandleId || ''
              },
              text: o.modelName || ''
            })
          })
        }

        return {
          ...createIcon,
          content
        }
      } else {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      }
    }
    case 'diagram-content-update': {
      if (action.props.connections !== undefined || action.props.objects !== undefined || action.props.comments !== undefined) {
        const addObjects = Object.keys(action.props.objects?.$add || {}).map(o => action.context.objects?.[o]).filter((o): o is ActionDiagramContentContextObject => !!o)
        const removeObjects = (action.props.objects?.$remove?.map(o => action.context.objects?.[o]) || []).filter((o): o is ActionDiagramContentContextObject => !!o)

        const addComments = Object.keys(action.props.comments?.$add || {}).map(o => action.context.comments?.[o]).filter((o): o is ActionDiagramContentContextComment => !!o)
        const removeComments = (action.props.comments?.$remove?.map(o => action.context.comments?.[o]) || []).filter((o): o is ActionDiagramContentContextComment => !!o)

        const addConnections = Object.keys(action.props.connections?.$add || {}).map(o => action.context.connections?.[o]).filter((o): o is ActionDiagramContentContextConnection => !!o && !!o.modelName)
        const removeConnections = (action.props.connections?.$remove?.map(o => action.context.connections?.[o]) || []).filter((o): o is ActionDiagramContentContextConnection => !!o && !!o.modelName)

        const updateConnectionsAdd = Object.entries(action.props.connections?.$update || {}).filter(([, val]) => val.modelId).map(([id]) => action.context.connections?.[id]).filter((o): o is ActionDiagramContentContextConnection => !!o && !!o.modelName)
        const updateConnectionsRemove = Object.entries(action.props.connections?.$update || {}).filter(([, val]) => val.modelId === null).map(([id]) => action.context.connections?.[id]).filter((o): o is ActionDiagramContentContextConnection => !!o && !!o.modelName)

        const model = action.context.modelFamily?.[0]

        const content: IActionLabelContent[] = [{
          text: 'Updated'
        }, {
          icon: diagramIcon,
          link: {
            diagramHandleId: action.id
          },
          text: model?.name ? `${model.name} ${wordify(action.context.type)}` : wordify(action.context.type),
          tooltip: action.context.name && action.context.name !== 'Entry diagram' ? action.context.name : undefined
        }]

        if (addObjects.length || addConnections.length || addComments.length || updateConnectionsAdd.length) {
          content.push({
            text: 'added'
          })
          addObjects.forEach(o => {
            content.push({
              icon: modelObjectIcons[o.modelType],
              link: {
                diagramHandleId: action.id,
                modelObjectHandleId: o.modelHandleId
              },
              text: o.modelName || o.modelType,
              tooltip: o.modelName ? capitalize(wordify(o.modelType)) : undefined
            })
          })
          const connections = [...addConnections, ...updateConnectionsAdd]
          connections.forEach(o => {
            content.push({
              icon: modelConnectionIcon,
              link: {
                diagramHandleId: action.id,
                modelConnectionHandleId: o.modelHandleId || ''
              },
              text: o.modelName || ''
            })
          })
          addComments.forEach(o => {
            content.push({
              icon: commentIcon,
              link: {
                commentHandleId: o.commentHandleId,
                diagramHandleId: action.id
              },
              text: o.commentBodyType || 'comment',
              tooltip: 'Comment'
            })
          })
        }

        if (removeObjects.length || removeConnections.length || removeComments.length || updateConnectionsRemove.length) {
          content.push({
            text: 'removed'
          })
          removeObjects.forEach(o => {
            content.push({
              icon: modelObjectIcons[o.modelType],
              link: {
                diagramHandleId: action.id,
                modelObjectHandleId: o.modelHandleId
              },
              text: o.modelName || o.modelType,
              tooltip: o.modelName ? capitalize(wordify(o.modelType)) : undefined
            })
          })
          const connections = [...removeConnections, ...updateConnectionsRemove]
          connections.forEach(o => {
            content.push({
              icon: modelConnectionIcon,
              link: {
                diagramHandleId: action.id,
                modelConnectionHandleId: o.modelHandleId || ''
              },
              text: o.modelName || ''
            })
          })
          removeComments.forEach(o => {
            content.push({
              icon: commentIcon,
              link: {
                commentHandleId: o.commentHandleId,
                diagramHandleId: action.id
              },
              text: o.commentBodyType || 'comment',
              tooltip: 'Comment'
            })
          })
        }

        return {
          ...updateIcon,
          content
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: `Updated ${wordify(action.context.type)} ${action.context.name}`
          }]
        }
      }
    }
    case 'diagram-content-delete': {
      const model = action.context.modelFamily?.[0]
      if (model && model.type !== 'root') {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'from'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.name || wordify(model.type),
            tooltip: model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: diagramIcon,
            link: {
              diagramHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      }
    }

    case 'diagram-group-create': {
      const model = action.context.modelFamily?.[0]
      if (model && model.type !== 'root') {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.props.handleId
            },
            text: action.props.name || 'Diagram group',
            tooltip: action.props.name ? 'Diagram group' : undefined
          }, {
            text: 'inside'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.name || wordify(model.type),
            tooltip: model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.props.handleId
            },
            text: action.props.name || 'Diagram group',
            tooltip: action.props.name ? 'Diagram group' : undefined
          }]
        }
      }
    }
    case 'diagram-group-update': {
      const model = action.context.modelFamily?.[0]
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.id
            },
            text: action.context.name || 'Diagram group',
            tooltip: action.context.name ? 'Diagram group' : undefined
          }, {
            text: 'to'
          }, {
            link: {
              diagramGroupHandleId: action.id
            },
            text: action.props.name || 'empty'
          }]
        }
      } else if (action.props.modelId !== undefined && model && model.type === 'root' && action.context.domainHandleId && action.context.domainName) {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.id
            },
            text: action.context.name || 'Diagram group',
            tooltip: action.context.name ? 'Diagram group' : undefined
          }, {
            text: 'to'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              domainHandleId: action.context.domainHandleId
            },
            text: action.context.domainName || 'Domain',
            tooltip: 'Domain'
          }]
        }
      } else if (action.props.modelId !== undefined && model && model.type !== 'root') {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.id
            },
            text: action.context.name || 'Diagram group',
            tooltip: action.context.name ? 'Diagram group' : undefined
          }, {
            text: 'to'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.name || wordify(model.type),
            tooltip: model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else if (action.props.modelId !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.id
            },
            text: action.context.name || 'Diagram group',
            tooltip: action.context.name ? 'Diagram group' : undefined
          }]
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: `Updated diagram group ${action.context.name}`
          }]
        }
      }
    }
    case 'diagram-group-delete': {
      const model = action.context.modelFamily?.[0]
      if (model && model.type !== 'root') {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.id
            },
            text: action.context.name || 'Diagram group',
            tooltip: action.context.name ? 'Diagram group' : undefined
          }, {
            text: 'from'
          }, {
            icon: modelObjectIcons[model.type],
            link: {
              modelObjectHandleId: model.handleId
            },
            text: model.name || wordify(model.type),
            tooltip: model.name ? capitalize(wordify(model.type)) : undefined
          }]
        }
      } else {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: diagramGroupIcon,
            link: {
              diagramGroupHandleId: action.id
            },
            text: action.context.name || 'Diagram group',
            tooltip: action.context.name ? 'Diagram group' : undefined
          }]
        }
      }
    }

    case 'domain-create': {
      return {
        ...createIcon,
        content: [{
          text: 'Created'
        }, {
          link: {
            domainHandleId: action.props.handleId
          },
          text: action.props.name || 'Domain'
        }]
      }
    }
    case 'domain-update': {
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed'
          }, {
            link: {
              domainHandleId: action.id
            },
            text: action.context.name || 'Domain'
          }, {
            text: 'to'
          }, {
            link: {
              domainHandleId: action.id
            },
            text: action.props.name || 'empty'
          }]
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: `Updated ${action.context.name}`
          }]
        }
      }
    }
    case 'domain-delete': {
      return {
        ...deleteIcon,
        content: [{
          text: 'Deleted'
        }, {
          link: {
            domainHandleId: action.id
          },
          text: action.context.name || 'Domain'
        }]
      }
    }

    case 'model-object-create': {
      const parent = action.context.modelFamily?.[0]
      if (action.props.parentId && parent && parent.type !== 'root') {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: modelObjectIcons[action.props.type],
            link: {
              modelObjectHandleId: action.props.handleId
            },
            text: action.props.name || wordify(action.props.type),
            tooltip: action.props.name ? capitalize(wordify(action.props.type)) : undefined
          }, {
            text: 'inside'
          }, {
            icon: modelObjectIcons[parent.type],
            link: {
              modelObjectHandleId: parent.handleId
            },
            text: parent.name || wordify(parent.type),
            tooltip: parent.name ? capitalize(wordify(parent.type)) : undefined
          }]
        }
      } else {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: modelObjectIcons[action.props.type],
            link: {
              modelObjectHandleId: action.props.handleId
            },
            text: action.props.name || wordify(action.props.type),
            tooltip: action.props.name ? capitalize(wordify(action.props.type)) : undefined
          }]
        }
      }
    }
    case 'model-object-update': {
      const parent = action.context.modelFamily?.[0]
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'to'
          }, {
            link: {
              modelObjectHandleId: action.id
            },
            text: action.props.name || 'empty'
          }]
        }
      } else if (action.props.type !== undefined && action.props.parentId && parent && parent.type !== 'root') {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'type to'
          }, {
            text: wordify(action.props.type)
          }, {
            text: 'inside'
          }, {
            icon: modelObjectIcons[parent.type],
            link: {
              modelObjectHandleId: parent.handleId
            },
            text: parent.name || wordify(parent.type),
            tooltip: parent.name ? capitalize(wordify(parent.type)) : undefined
          }]
        }
      } else if (action.props.type !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'type to'
          }, {
            text: wordify(action.props.type)
          }]
        }
      } else if (action.props.parentId && parent && parent.type === 'root' && action.context.domainName && action.context.domainHandleId) {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'to'
          }, {
            icon: modelObjectIcons[parent.type],
            link: {
              domainHandleId: action.context.domainHandleId
            },
            text: action.context.domainName || 'Domain',
            tooltip: 'Domain'
          }]
        }
      } else if (action.props.parentId && parent && parent.type !== 'root') {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'to'
          }, {
            icon: modelObjectIcons[parent.type],
            link: {
              modelObjectHandleId: parent.handleId
            },
            text: parent.name || wordify(parent.type),
            tooltip: parent.name ? capitalize(wordify(parent.type)) : undefined
          }]
        }
      } else if (action.props.parentId !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Moved'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      } else if (action.props.external !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: `as ${action.props.external ? 'external' : 'internal'}`
          }]
        }
      } else if (action.props.caption !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'display description'
          }]
        }
      } else if (action.props.description !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'description'
          }]
        }
      } else if (action.props.teamOnlyEditing !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: `editable to ${action.props.teamOnlyEditing ? 'owners' : 'editors'}`
          }]
        }
      } else if (action.props.status !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: `status to ${action.props.status || 'none'}`
          }]
        }
      } else if (action.props.icon !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: `icon to ${action.props.icon?.name || 'none'}`
          }]
        }
      } else if (action.props.links !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'links'
          }]
        }
      } else if (action.props.technologies !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'technologies'
          }]
        }
      } else if (action.props.groupIds !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'groups'
          }]
        }
      } else if (action.props.tagIds !== undefined) {
        const tagNames = action.props.tagIds.map(o => action.context.tagNames?.[o]).filter((o): o is string => !!o)
        if (tagNames.length) {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: modelObjectIcons[action.context.type],
              link: {
                modelObjectHandleId: action.id
              },
              text: action.context.name || wordify(action.context.type),
              tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
            }, {
              text: `tags to ${tagNames.join(', ').toLowerCase()}`
            }]
          }
        } else {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: modelObjectIcons[action.context.type],
              link: {
                modelObjectHandleId: action.id
              },
              text: action.context.name || wordify(action.context.type),
              tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
            }, {
              text: 'tags'
            }]
          }
        }
      } else if (action.props.teamIds !== undefined) {
        const teamNames = action.props.teamIds.map(o => action.context.teamNames?.[o]).filter((o): o is string => !!o)
        if (teamNames.length) {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: modelObjectIcons[action.context.type],
              link: {
                modelObjectHandleId: action.id
              },
              text: action.context.name || wordify(action.context.type),
              tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
            }, {
              text: `teams to ${teamNames.join(', ')}`
            }]
          }
        } else {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: modelObjectIcons[action.context.type],
              link: {
                modelObjectHandleId: action.id
              },
              text: action.context.name || wordify(action.context.type),
              tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
            }, {
              text: 'teams'
            }]
          }
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      }
    }
    case 'model-object-delete': {
      const parent = action.context.modelFamily?.[0]
      if (parent && parent.type !== 'root') {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }, {
            text: 'from'
          }, {
            link: {
              modelObjectHandleId: parent.handleId
            },
            text: parent.name || wordify(parent.type),
            tooltip: parent.name ? capitalize(wordify(parent.type)) : undefined
          }]
        }
      } else {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: modelObjectIcons[action.context.type],
            link: {
              modelObjectHandleId: action.id
            },
            text: action.context.name || wordify(action.context.type),
            tooltip: action.context.name ? capitalize(wordify(action.context.type)) : undefined
          }]
        }
      }
    }

    case 'model-connection-create': {
      const origin = action.context.originModelFamily?.[0]
      const target = action.context.targetModelFamily?.[0]
      if (origin && target) {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.props.handleId
            },
            text: action.props.name || 'Connection'
          }, {
            text: 'from'
          }, {
            icon: modelObjectIcons[origin.type],
            link: {
              modelObjectHandleId: origin.handleId
            },
            text: origin.name || wordify(origin.type),
            tooltip: origin.name ? capitalize(wordify(origin.type)) : undefined
          }, {
            text: 'to'
          }, {
            icon: modelObjectIcons[target.type],
            link: {
              modelObjectHandleId: target.handleId
            },
            text: target.name || wordify(target.type),
            tooltip: target.name ? capitalize(wordify(target.type)) : undefined
          }]
        }
      } else {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.props.handleId
            },
            text: action.props.name || 'Connection'
          }]
        }
      }
    }
    case 'model-connection-update': {
      const origin = action.context.originModelFamily?.[0]
      const target = action.context.targetModelFamily?.[0]
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.context.name || 'Connection'
          }, {
            text: 'to'
          }, {
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'empty'
          }]
        }
      } else if (action.props.originId !== undefined && origin) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'Connection'
          }, {
            text: 'origin to'
          }, {
            icon: modelObjectIcons[origin.type],
            link: {
              modelObjectHandleId: origin.handleId
            },
            text: origin.name || wordify(origin.type),
            tooltip: origin.name ? capitalize(wordify(origin.type)) : undefined
          }]
        }
      } else if (action.props.originId !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'Connection'
          }, {
            text: 'origin'
          }]
        }
      } else if (action.props.targetId !== undefined && target) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'Connection'
          }, {
            text: 'target to'
          }, {
            icon: modelObjectIcons[target.type],
            link: {
              modelObjectHandleId: target.handleId
            },
            text: target.name || wordify(target.type),
            tooltip: target.name ? capitalize(wordify(target.type)) : undefined
          }]
        }
      } else if (action.props.targetId !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'Connection'
          }, {
            text: 'target'
          }]
        }
      } else if (action.props.description !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'Connection'
          }, {
            text: 'description'
          }]
        }
      } else if (action.props.direction !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'Connection'
          }, {
            text: `direction to ${action.props.direction ? wordify(action.props.direction) : 'none'}`
          }]
        }
      } else if (action.props.tagIds !== undefined) {
        const tagNames = action.props.tagIds.map(o => action.context.tagNames?.[o]).filter((o): o is string => !!o)
        if (tagNames.length) {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: modelConnectionIcon,
              link: {
                modelConnectionHandleId: action.id
              },
              text: action.context.name || 'Connection'
            }, {
              text: `tags to ${tagNames.join(', ')}`
            }]
          }
        } else {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: modelConnectionIcon,
              link: {
                modelConnectionHandleId: action.id
              },
              text: action.context.name || 'Connection'
            }, {
              text: 'tags'
            }]
          }
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.props.name || 'Connection'
          }]
        }
      }
    }

    case 'model-connection-delete': {
      const origin = action.context.originModelFamily?.[0]
      const target = action.context.targetModelFamily?.[0]
      if (origin && target) {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.context.name || 'Connection'
          }, {
            text: 'between'
          }, {
            icon: modelObjectIcons[origin.type],
            link: {
              modelObjectHandleId: origin.handleId
            },
            text: origin.name || wordify(origin.type),
            tooltip: origin.name ? capitalize(wordify(origin.type)) : undefined
          }, {
            text: 'and'
          }, {
            icon: modelObjectIcons[target.type],
            link: {
              modelObjectHandleId: target.handleId
            },
            text: target.name || wordify(target.type),
            tooltip: target.name ? capitalize(wordify(target.type)) : undefined
          }]
        }
      } else {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: modelConnectionIcon,
            link: {
              modelConnectionHandleId: action.id
            },
            text: action.context.name || 'Connection'
          }]
        }
      }
    }

    case 'flow-view': return {
      ...viewedIcon,
      content: [{
        text: 'Viewed'
      }, {
        icon: flowIcon,
        link: {
          flowHandleId: action.id
        },
        text: action.context.name
      }]
    }
    case 'flow-create': return {
      ...createIcon,
      content: [{
        text: 'Created'
      }, {
        icon: flowIcon,
        link: {
          flowHandleId: action.props.handleId
        },
        text: action.props.name
      }]
    }
    case 'flow-update': {
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed '
          }, {
            icon: flowIcon,
            link: {
              flowHandleId: action.id
            },
            text: action.context.name
          }, {
            text: 'to'
          }, {
            link: {
              flowHandleId: action.id
            },
            text: action.props.name
          }]
        }
      } else if (action.props.steps !== undefined) {
        const addCount = Object.keys(action.props.steps.$add || {}).length
        const removeCount = Object.keys(action.props.steps.$remove || {}).length
        if (addCount && removeCount) {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: flowIcon,
              link: {
                flowHandleId: action.id
              },
              text: action.context.name
            }, {
              text: `added removed step${addCount === 1 ? '' : 's'} removed ${removeCount} step${removeCount === 1 ? '' : 's'}`
            }]
          }
        } else if (addCount) {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: flowIcon,
              link: {
                flowHandleId: action.id
              },
              text: action.context.name
            }, {
              text: `added step${addCount === 1 ? '' : 's'}`
            }]
          }
        } else if (removeCount) {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: flowIcon,
              link: {
                flowHandleId: action.id
              },
              text: action.context.name
            }, {
              text: `removed step${removeCount === 1 ? '' : 's'}`
            }]
          }
        } else {
          return {
            ...updateIcon,
            content: [{
              text: 'Updated'
            }, {
              icon: flowIcon,
              link: {
                flowHandleId: action.id
              },
              text: action.context.name
            }, {
              text: 'steps'
            }]
          }
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: flowIcon,
            link: {
              flowHandleId: action.id
            },
            text: action.context.name
          }]
        }
      }
    }
    case 'flow-delete': return {
      ...deleteIcon,
      content: [{
        text: 'Deleted'
      }, {
        icon: flowIcon,
        link: {
          flowHandleId: action.id
        },
        text: action.context.name
      }]
    }

    case 'tag-create': {
      if (action.context.groupName) {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: tagIcon,
            text: action.props.name || 'Tag'
          }, {
            text: 'inside'
          }, {
            icon: tagGroupIcon,
            text: action.context.groupName
          }]
        }
      } else {
        return {
          ...createIcon,
          content: [{
            text: 'Created'
          }, {
            icon: tagIcon,
            text: action.props.name || 'Tag'
          }]
        }
      }
    }
    case 'tag-update': {
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed'
          }, {
            icon: tagIcon,
            text: action.context.name || 'Tag'
          }, {
            text: 'to'
          }, {
            text: action.props.name || 'Tag'
          }]
        }
      } else if (action.props.color !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: tagIcon,
            text: action.context.name || 'Tag'
          }, {
            text: `color to ${wordify(action.props.color)}`
          }]
        }
      } else if (action.props.groupId !== undefined && action.context.groupName) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: tagIcon,
            text: action.context.name || 'Tag'
          }, {
            text: 'group to'
          }, {
            icon: tagGroupIcon,
            text: action.context.groupName
          }]
        }
      } else if (action.props.groupId !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: tagIcon,
            text: action.context.name || 'Tag'
          }, {
            text: 'group'
          }]
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: tagIcon,
            text: action.context.name || 'Tag'
          }]
        }
      }
    }
    case 'tag-delete': {
      if (action.context.groupName) {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: tagIcon,
            text: action.context.name || 'Tag'
          }, {
            text: 'from'
          }, {
            icon: tagGroupIcon,
            text: action.context.groupName
          }]
        }
      } else {
        return {
          ...deleteIcon,
          content: [{
            text: 'Deleted'
          }, {
            icon: tagIcon,
            text: action.context.name || 'Tag'
          }]
        }
      }
    }

    case 'tag-group-create': return {
      ...createIcon,
      content: [{
        text: 'Created'
      }, {
        icon: tagGroupIcon,
        text: action.props.name || 'Tag group'
      }]
    }
    case 'tag-group-update': {
      if (action.props.name !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Renamed'
          }, {
            icon: tagGroupIcon,
            text: action.context.name || 'Tag group'
          }, {
            text: 'to'
          }, {
            text: action.props.name || 'Tag group'
          }]
        }
      } else if (action.props.icon !== undefined) {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: tagGroupIcon,
            text: action.props.name || 'Tag group'
          }, {
            text: `icon to ${wordify(action.props.icon)}`
          }]
        }
      } else {
        return {
          ...updateIcon,
          content: [{
            text: 'Updated'
          }, {
            icon: tagGroupIcon,
            text: action.props.name || 'Tag group'
          }]
        }
      }
    }
    case 'tag-group-delete': return {
      ...deleteIcon,
      content: [{
        text: 'Deleted'
      }, {
        icon: tagGroupIcon,
        text: action.context.name || 'Tag group'
      }]
    }

    default: return {
      content: [{
        text: action.type
      }]
    }
  }
}
